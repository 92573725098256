<template>
  <!-- 订单账单 -->
  <div class="container">
    <a-tabs :default-active-key="tabIndex" :animated="false" @change="callback">
      <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name">
        <div class="main-content">
          <div class="main-content-header">
            <div class="item">
              <div class="title">单品订单</div>
            </div>
            <div class="item">
              <a-button icon="download" @click="isModalExport = true">
                批量导出
              </a-button>
            </div>
          </div>
          <my-table
            :columns="columns"
            :data-source="item.dataSource"
            :pagination="false"
            :loading="item.loading"
            :scroll="{ x: 1150, y: true }"
            :row-key="(record) => record.orderId"
            :row-selection="rowSelection"
          >
            <template slot="status" slot-scope="record">
              <span>{{ $orderStatusText[record.status] }}</span>
            </template>
            <template slot="totalFee" slot-scope="record">
              <a-statistic
                class="a-statistic"
                prefix="￥"
                :value="record.totalFee"
              />
            </template>
            <template slot="paidFee" slot-scope="record">
              <a-statistic
                class="a-statistic"
                prefix="￥"
                :value="record.paidFee"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link">
                <router-link
                  :to="{
                    path: '/orderItemAccountsInfo',
                    query: { id: record.orderId, type: item.type}
                  }"
                >
                  更多详情
                </router-link>
              </a-button>
            </template>
            <div class="pagination" slot="footer">
              <div class="export-box">
                <template v-if="selectedRowKeys.length">
                  <span class="export-label">已选</span>
                  <span class="export-num">{{ selectedRowKeys.length }}</span>
                  <span class="export-label">条</span>
                  <a-divider type="vertical" />
                  <span class="export-label">批量：</span>
                  <a-button
                    class="export-btn"
                    icon="download"
                    @click="isModalExportCheck = true"
                  >
                    导出已选
                  </a-button>
                </template>
              </div>
              <a-pagination
                :default-current="item.pagination.current"
                :total="item.pagination.total"
                @change="handlePaginationChange"
              />
            </div>
          </my-table>
        </div>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      :title="'批量导出 - ' + title"
      :visible="isModalExport"
      :footer="false"
      :width="700"
      @cancel="onModalExportCancel"
    >
      <div class="find-order">
        <div class="find">
          <div class="timer-picker">
            <span class="ribbon-label">按完成时间段：</span>
            <a-date-picker v-model="startAt" placeholder="开始日期" />
            <span class="ribbon-center-text">至</span>
            <a-date-picker v-model="endAt" placeholder="结束日期" />
          </div>
          <div class="btn-box">
            <a-button
              class="search-btn"
              type="primary"
              :disabled="!startAt || !endAt"
              :loading="findBtnLoading"
              @click="onFindOrder"
            >
              查 询
            </a-button>
            <a-button
              class="reset-btn"
              :disabled="!startAt && !endAt"
              @click="onReset"
            >
              重 置
            </a-button>
          </div>
        </div>
        <a-alert
          :message="`（${findTime}）时间段内查询的订单，共${findNum}条`"
          type="success"
          show-icon
          v-if="isFindFinish"
        />
        <div class="footer-btn" v-if="isFindFinish">
          <a-button
            class="export-btn"
            type="primary"
            :loading="exportBtnLoading"
            :disabled="!findNum"
            @click="onExport"
          >
            导出
          </a-button>
        </div>
      </div>
    </a-modal>

    <a-modal title="导出已选" :visible="isModalExportCheck">
      <div class="content">
        已选
        {{ selectedRowKeys.length }}
        条数据（提示：数据中的图片、附件、手写签名暂不支持导出）
      </div>
      <template slot="footer">
        <a-button key="back" @click="onModalExportCheckCancel">
          取 消
        </a-button>
        <a-button
          class="search-btn"
          type="primary"
          key="submit"
          :loading="exportBtnLoading"
          @click="onExport"
        >
          导 出
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      title: '',
      tabIndex: 0,
      tabs: [
        {
          key: 0,
          name: '家电清洗',
          type: 'serving',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        },
        {
          key: 1,
          name: '汽车美容',
          type: 'card',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        },
        {
          key: 2,
          name: '环境治理',
          type: 'surroundings',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        },
        {
          key: 3,
          name: '购物商城',
          type: 'goods',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        },

      ],
      startAt: '',
      endAt: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '订单号',
          width: 200,
          dataIndex: 'outTradeNo'
        },
        {
          title: '订单状态',
          width: 150,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '订单总金额',
          width: 150,
          scopedSlots: { customRender: 'totalFee' }
        },
        {
          title: '实际付款金额',
          width: 150,
          scopedSlots: { customRender: 'paidFee' }
        },
        {
          title: '订单完成时间',
          dataIndex: 'finishAt'
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      selectedRowKeys: [],
      isModalExport: false,
      findTime: '',
      findNum: 0,
      findBtnLoading: false,
      isFindFinish: false,
      exportBtnLoading: false,
      isModalExportCheck: false
    }
  },
  computed: {
    ...mapState(['$orderStatusText']),
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (key) => {
          this.selectedRowKeys = key
        }
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getOrderItemAccounts()
  },
  methods: {
    moment,
    callback(key) {
      // tab切换
      this.tabIndex = key
      if (this.selectedRowKeys.length) {
        this.selectedRowKeys = []
      }
      if (key === 1 && !this.tabs[1].dataSource.length) {
        this.getAccountIinedxCard()
      } else if (key === 2 && !this.tabs[2].dataSource.length) {
        this.getOrderSurroundingsAccounts()
      } else if (key === 3 && !this.tabs[3].dataSource.length) {
        this.getOrderGoodsAccounts()
      }
    },
    getOrderItemAccounts() {
      // 获取单品订单列表
      const tab = this.tabs[0]
      const data = {
        page: tab.pagination.current,
        limit: 10
      }
      this.$axios.getOrderItemAccounts(data).then((res) => {
        const d = res.data.data
        tab.dataSource = d.list
        tab.pagination.total = d.count
        tab.loading = false
      })
    },
    getAccountIinedxCard() {
      const tab = this.tabs[1]
      const data = {
        page: tab.pagination.current,
        limit: 10
      }
      this.$axios.getAccountIinedxCard(data).then((res) => {
        const d = res.data.data
        tab.dataSource = d.list
        tab.pagination.total = d.count
        tab.loading = false
      })
    },
    getOrderSurroundingsAccounts() {
      // 获取环境治理订单列表
      const tab = this.tabs[1]
      const data = {
        page: tab.pagination.current,
        limit: 10
      }
      this.$axios.getOrderSurroundingsAccounts(data).then((res) => {
        const d = res.data.data
        tab.dataSource = d.list
        tab.pagination.total = d.count
        tab.loading = false
      })
    },
    getOrderGoodsAccounts() {
      // 获取商品订单列表
      const tab = this.tabs[2]
      const data = {
        page: tab.pagination.current,
        limit: 10
      }
      this.$axios.getOrderGoodsAccounts(data).then((res) => {
        const d = res.data.data
        tab.dataSource = d.list
        tab.pagination.total = d.count
        tab.loading = false
      })
    },
    handlePaginationChange(e) {
      const key = this.tabIndex
      const tab = this.tabs[key]
      tab.loading = true
      tab.pagination.current = e
      if (key === 0) {
        this.getOrderItemAccounts()
      } else if (key === 1) {
        this.getAccountIinedxCard()
      } else if (key === 2) {
        this.getOrderSurroundingsAccounts()
      } else {
        this.getOrderGoodsAccounts()
      }
    },
    onModalExportCancel() {
      // 取消-批量导出
      this.isModalExport = false
      this.exportBtnLoading = false
      this.isFindFinish = false
      this.onReset()
    },
    onFindOrder() {
      // 查询订单导出数量
      this.findBtnLoading = true
      const startAt = moment(this.startAt).format('YYYY-MM-DD') + ' 00:00:00'
      const endAt = moment(this.endAt).format('YYYY-MM-DD') + ' 23:59:59'
      const time = `${startAt.substr(0, 10)} 至 ${endAt.substr(5, 5)}`
      this.findTime = time
      const data = {
        type: 2,
        startAt: startAt,
        endAt: endAt
      }
      this.$axios.findOrderExport(data).then((res) => {
        this.findNum = Number(res.data.data)
        this.findBtnLoading = false
        this.isFindFinish = true
      })
    },
    onReset() {
      // 重置时间
      this.startAt = ''
      this.endAt = ''
      this.isFindFinish = false
    },
    onExport() {
      // 批量导出
      this.exportBtnLoading = true
        const key = this.tabIndex
      const data = {
        type: 2
      }
      if(key == 1){
        data.btype = "card"
      }
      if (this.startAt) {
        data.startAt = moment(this.startAt).format('YYYY-MM-DD') + ' 00:00:00'
        data.endAt = moment(this.endAt).format('YYYY-MM-DD') + ' 23:59:59'
      }
      if (this.selectedRowKeys.length) {
        data.id = this.selectedRowKeys
      }
      console.log("data",data)
      this.$axios.orderExport(data).then((res) => {
        this.exportBtnLoading = false
        this.$message.success('数据导出Excel成功')
        if (this.startAt) {
          this.onModalExportCancel()
        }
        if (this.selectedRowKeys.length) {
          this.onModalExportCheckCancel()
        }
        window.open(res.data.data)
      })
    },
    onModalExportCheckCancel() {
      // 取消-导出已选
      this.selectedRowKeys = []
      this.isModalExportCheck = false
      this.exportBtnLoading = false
    }
  }
}
</script>

<style scoped>
.find-order .find {
  display: flex;
  justify-content: space-between;
}

.timer-picker {
  display: flex;
  align-items: center;
}

.ribbon-center-text {
  margin: 0 8px;
}

.find-order .ant-calendar-picker {
  width: 160px;
}

.find-order .ant-alert {
  margin: 16px 0 24px 0;
}

.find-order .reset-btn {
  margin-left: 8px;
}

.find-order .footer-btn {
  overflow: hidden;
}

.find-order .export-btn {
  float: right;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.export-box {
  display: flex;
  align-items: center;
}

.export-box .export-label,
.export-box .export-num {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.export-box .export-label {
  color: #333333;
}

.export-box .export-num {
  color: #177ddc;
  margin: 0 8px;
}

.export-box .export-btn {
  margin-left: 12px;
}
</style>
